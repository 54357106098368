<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-07 14:12:59
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-18 11:16:15
 * @FilePath: \website\src\pages\components\header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
   <div class="header row no-gutters d-flex justify-content-center">
        <div class="col-11 col-xl-8  d-flex justify-content-between header-container" :class="{'navOpen':state.drawer}">
            <div class="left cursor-pointer" @click="scrollToAnchor('section1Ref')">
                <img src="@/assets/logo.png" alt="" srcset="">
            </div>
            <div class="right">
                <div class="lists height-100-all d-flex align-items-center">
                    <template v-for="(item,index) in state.lists"  :key="index">
                        <div class="list cursor-pointer" :class="{'active':state.activeName == item.name}" @click="scrollToAnchor(item.name)">{{item.title}}</div>
                    </template>
                </div>
                <burger :lists="state.lists" @change="scrollToAnchor"/>
            </div>
        </div>
   </div>
   
</template>

<script setup>
import burger from "./burger"
import { reactive } from 'vue';
const emits = defineEmits(['change'])
let state = reactive({
    activeName:"sectionRef",
    lists:[
        {
            name:"sectionRef",
            title:"首页"
        },
        
        {
            name:"section2Ref",
            title:"健康资讯"
        },
        {
            name:"section3Ref",
            title:"下载体验"
        },
        {
            name:"section4Ref",
            title:"健康知识"
        },
        {
            name:"section6Ref",
            title:"健康工具"
        },
        {
            name:"section5Ref",
            title:"联系我们"
        }

    ]
})
function scrollToAnchor(refStr){
    state.activeName = refStr;
    emits("change",refStr)
}

</script>

<style lang="scss" scoped>
.header{
    background: white;
    .header-container{
        .left{
            padding: 2.6rem 0;
            img{
                height: 3.6rem;
            }
        }
        .right{
            flex: 1;
            max-width: 74.6rem;
            
            .list{
                font-size: 1.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: bold;
                color: #666666;
                flex-shrink: 0;
                text-align: center;
                position: relative;
                min-width: 12.8rem;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .list.active,.list:hover{
                font-size: 1.8rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                background: #1EBB81;
            }
            
        }
    }
}
@media screen and (max-width: 767px) {
    .lists {
        display: none!important;
        
    }
    .right{
        display: flex;
        align-items: center;
        flex: none!important;
    }
}
// @media screen and (min-width: 768px) and (max-width: 1024px) {
//     .list {
//         width: 128px;
//     }
//     .lists {
//         display: flex!important;
//     }
//     #burger{
//         display: none!important;
//     }
// }

</style>