<template>
    <div class="swiperHome d-flex justify-content-center" ref="swiperHome">
      <div class="col-sm-11 col-xs-12 col-xl-8 ">
          <div>
            <div class="title">
              <div class="text">养生堂App</div>
            </div>
            <div class="text">探索养生奥秘，享受健康人生</div>
          </div>
        <div class=" d-flex content  justify-content-between">
          <div class="left content_left wow fadeInUp"  id="reveal-top" data-wow-duration="0.6s" data-wow-delay="0s">
          
            <div class="lists" ref="lists">
              <template v-for="(item,index) in pageData.lists" :key="index">
                <div class="list cursor-pointe  cursor-pointer d-flex align-items-center" :class="{'active':pageData.swiperIndex == index}" @mouseover="swiperChange(index)">
                  <div class="left">
                    <img :src="require(`@/assets/${pageData.swiperIndex == index  ? item.srcActive : item.src}.png`)" alt="" srcset="">
                  </div>
                  <div class="right">
                    <div class="top">
                    
                      <span class="text-value">{{item.name}}</span>
                    </div>
                    <div class="bottom">{{item.text}}</div>
                  </div>
                </div>
              </template>
              
            </div>
            <div class="down">
                <div class="content d-flex align-items-center cursor-pointer">
                    <div class="downDmo downclick">
                        <div class="text_val">下载养生堂APP</div>
                        <img src="@/assets/arrow.png" alt="" srcset="">
                        
                    </div>
                    <img class="code" src="@/assets/code.png" alt="" srcset="">
                </div>
            </div>
          </div>
          <div class="center wow fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">
            <swiper class="mySwiper" 
            :modules="modules"
            :effect="'fade'"
            :autoplay="pageData.autoplay"
            :spaceBetween="30"
            :loop="true"
            @swiper="setControlledSwiper"
            @slideChange="onSlideChange"
            >
                <swiper-slide class="swiper-slide lists" v-for="(item, i) in pageData.lists" :key="i">
                  <img :src="require(`@/assets/${item.activeImg}.png`)" alt="" srcset="">
                  <div class="list cursor-pointe active">
                    <div class="top">
                      <img :src="require(`@/assets/${pageData.lists[i].srcActive}.png`)" alt="" srcset="">
                      <span class="text-value">{{pageData.lists[i].name}}</span>
                    </div>
                    <div class="bottom">{{pageData.lists[i].text}}</div>
                  </div>
                </swiper-slide>
              </swiper>
          </div>  
        </div>
        
      </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide  } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Autoplay,EffectFade } from 'swiper/modules';
import { reactive,ref  } from "vue"
import { mainStore } from "@/sheep/store"
const store = mainStore()
const modules = [ Autoplay,EffectFade]
const swiperHome = ref(null);
const lists = ref(null);
let pageData = reactive({
  lists:[{
      name:"首页",
      src:"index",
      srcActive:"index_active",
      text:"前沿健康知识、资讯内容",
      activeImg:"index-active-bg"
    },{
      name:"发现",
      src:"find",
      srcActive:"find_active",
      text:"养生健康操，跳出活力人生",
      activeImg:"find-active-bg"
    },{
      name:"健康专家",
      src:"expert",
      srcActive:"expert",
      text:"舌诊、体质测评、24在线解答健康问题",
      activeImg:"expert-active-bg"
    },{
      name:"计划",
      src:"cart",
      srcActive:"cart_active",
      text:"全方面规划您的健康生活",
      activeImg:"cart-active-bg"
    },{
      name:"我的",
      src:"my",
      srcActive:"my_active",
      text:"种树得现金、水果。打卡养成健康好习惯",
      activeImg:"my-active-bg"
    }],
    swiperIndex:0,
		controlledSwiper:null,
    autoplay:{
      delay: 50000000,
    }
})
function setControlledSwiper(swiper){
  pageData.controlledSwiper = swiper
}
function onSlideChange(swiper){
  pageData.swiperIndex = swiper.realIndex;
}

function swiperChange(idx){
  pageData.controlledSwiper.slideToLoop(idx);
}

</script>

<style scoped lang="scss">
.swiperHome{
  padding-top: 5.3rem;
  padding-bottom: 7.3rem;
  overflow: hidden;
  background: url(@/assets/swiper_bg.png) no-repeat;
  background-size:100% 100%;
  .title{
      position: relative;
      .text{
        font-size: 3.5rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 0;
      }
    }
    .text{
      font-size: 1.6rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #A7A9A9;
      margin-top: 1.6rem;
    }
    .lists{
      margin-top: 50px;
    }
  }
  .center{
    width: 38.3rem;
    margin: 0 9.7rem;
    img{
      width: 100%;
    }
    .list{
      display: none;
    }
  }
  .content_left{
    width: calc( (100% - 45rem) / 2 );
  }
  
  .right{
    .lists{
      margin-top: 40rem;
    }
    .code-lists{
      .code{
        margin-right: 6.3rem;
      }
      .code:last-child{
        margin-right: 0;
      }
    }
    .code{
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      img{
        width:13.6rem;
        height: 13.6rem;
      }
      span{
        font-size: 1.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 2rem;
      }
    }
  }
.lists{
    .left{
      margin-right: 1.3rem;
    }
  .list{
    margin-bottom: 1.3rem;
    padding: 2.2rem 2rem;
    border-radius: 8px;
    background: #FFFFFF;
    border-radius: 13px;
    border: 1px solid #F2F2F2;
  }
  
  .top{
    margin-bottom: 1.6rem;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    img{
      width: 4.4rem;
    }
    .text-value{
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 1.9rem;
      color: #666666;
    }
    
  }
  .bottom{
      font-family: PingFangSC;
      font-size: 1.5rem;
      color: #999999;
    }
  .list.active{
    border: 1px solid #DFEAE6;
    .text-value{
      color: #3F9B79;
    }
  }
}
.down{
  .content{
            width: fit-content;
            position: relative;
        }
    .code{
        width: 10vw;
        margin-left: 1vw;
        opacity: 0;
        transition: all .4s;
        position: absolute;
        left: 100%;
    }
    .content:hover{
            .code{
                opacity: 1;
                transition: all .4s;
            }
            
        }
}
.downDmo{
    padding: 1vw 5rem;
    background: #1EBB81;
    display: inline-flex;
    align-items: center;
    color:white;
    border-radius: 5px;
    font-size: 2.1rem;
    font-weight: bold;
    margin-top: 3.7rem;
    img{
        height: 2rem;
        margin-left: 3rem;
    }
}
@media screen and (max-width: 767px) {
		.swiperHome{
      padding: 1.6rem ;
      margin: 6px 8px 0 8px;
      .title{
        margin-top: 10px;
      }
      .content{
        border-radius: $border-radius-con;
        // flex-direction: row-reverse;
        // margin-top: 20px;
        align-items: inherit;
        justify-content: center !important;
      }
      .left{
        width: 100%;
        padding: 0;
        display: none;
        .title{
          img{
            width: 8.8rem;
            height: 8.8rem;
          }
          .text{
            font-size: $font-size-26;
            bottom: 6px;
          }
        }
      }
      .center{
        width: 100%;
        margin-top: 3rem;
        .lists{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
              margin-top: 0px;
          img{
            width: 50%!important;
          }
        }
        .list{
          display: block;
          margin: 0 ;
          margin-top: 3rem;
          width: 80%;
          min-height: 16rem;
          .top{
            img{
              width: 4rem!important;
            }
          }
          .bottom{
            font-size: 2rem;
          }
        }
      }
      // .content_left{
      //   width: 50%;
      //   padding: 0;
      //   .lists{
      //     margin-left: 10px;
      //     .list{
      //       padding: 11px 8px;
      //       .left{
      //         margin-right: 5px;
      //         align-items: center;
      //         display: flex;
      //         img{
      //           width: 27px;
      //           height: 27px;
      //         }
      //       }
      //       .right{
      //         .top{
      //           margin-bottom: 4px;
      //         }
      //         .bottom{
      //           line-height: 1.2;
      //         }
      //       }
      //     }
      //   }
      //   .down{
      //     margin-left: 10px;
      //     .content {
      //       width: 100%;
      //     }
      //     .downDmo{
      //       margin-top: 0;
      //       height: 38px;
      //       font-size: 1.6rem;
      //       width: 100%;
      //       display: flex;
      //       padding: 1vw 17px;
      //           justify-content: center;
      //     }
      //     .code{
      //         display: none;
      //       }
      //       .text_val{
      //         margin-top: 3px;
              
      //       }
      //       img{
      //           margin-left: 14px;
      //         }
      //   }
      // }
      // .center{
      //   width: 50%;
      //   margin: 0;
      // }
      // .lists{
      //   margin-top: 0;
      // }
    }
}
</style>
  