<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-09 09:27:54
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-22 15:39:06
 * @FilePath: \website\src\pages\components\banner.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
<div class="banner" v-if="store.isApp">
        <img class="banner-bg downclick" src="@/assets/banner-iphone.png" alt="" srcset="">
    </div>
    <div class="banner" v-else>
        <img class="banner-bg" src="@/assets/banner.png" alt="" srcset="">
        <div class="container-banner row no-gutters d-flex justify-content-center">
            <div class="down col-8 col-xl-8">
                <div class="content d-flex align-items-center cursor-pointer">
                    <div class="downDmo">
                        <span>下载养生堂APP</span>
                        <img src="@/assets/arrow.png" alt="" srcset="">
                        
                    </div>
                    <img class="code" src="@/assets/code.png" alt="" srcset="">
                </div>
            </div>
            
        </div>
    </div>
</template>

<script setup>
import { mainStore } from "@/sheep/store"
const store = mainStore()
function gotoApp(){
    if(store.isApp){
        // location.href='https://zx-download.aihua.com/'
    }
    
}

</script>

<style lang="scss" scoped>
.banner{
    position:relative;
    padding-top: 8.8rem;
    .banner-bg{
        width:100%;
    }
    .bottom-img{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
    }
    .container-banner{
        position: absolute;
        bottom: 20%;
        width:100%;
        .downDmo{
            padding: 1vw 5rem;
            background: #1EBB81;
            border-radius: 100px;
            display: inline-flex;
            align-items: center;
            color:white;
            font-size: 2.1rem;
            font-weight: bold;
            img{
                height: 2rem;
                margin-left: 3rem;
            }
        }
        
        .content{
            width: fit-content;
        }
        .down{
            .code{
                width: 10vw;
                margin-left: 1vw;
                opacity: 0;
                transition: all .4s;
            }
        }
        .content:hover{
            .code{
                opacity: 1;
                transition: all .4s;
            }
            
        }
    }
    @media screen and (max-width: 767px) {
        .container-banner{
            bottom: 4rem!important;
            .downDmo{
                padding: 1.1rem 1.7rem;
                font-size: 1.4rem;
                // img{
                //     height: 1.0417vw;
                //     margin-left: .3646vw;
                // }
            }
            
        }
    }
}

</style>