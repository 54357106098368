<template>
    <div class="footnoteHome d-flex justify-content-center">
        <div class="col-sm-11 col-xs-12 col-xl-8 foot-app"   v-if="store.isApp">
            <div class="content d-flex justify-content-between">
                <div class="center wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <ul>
                        <li>
                            <img src="@/assets/iphone.png" alt="" srcset="">
                            <span>4006699955</span>
                        </li>
                        <li>
                           <div>
                                <img src="@/assets/qq.png" alt="" srcset="">
                                <span>25538</span>
                           </div>
                        </li>
                        <li>
                            <div>
                                <img src="@/assets/message.png" alt="" srcset="">
                                <span>25538@qq.com</span>
                            </div>
                        </li>
                        <!-- <li @click="clkonline" class="cursor-pointer d-flex justify-content-between align-items-center">
                            <div>
                                <img src="@/assets/customer.png" alt="" srcset="">
                                <span>点击联系客服</span>
                            </div>
                            <i class="bi bi-chevron-right"></i>
                        </li> -->
                    </ul>
                </div>
                
            </div>
        </div>
        <div class="col-sm-11 col-xs-12 col-xl-8 foot-content" >
            <div class="content d-flex justify-content-between">
                <div class="left  wow fadeInUp d-flex justify-content-between align-items-center" data-wow-duration="0.4s" data-wow-delay="0s">
                    <img src="@/assets/logo.png" alt="" srcset="">
                </div>
                <div class="center wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.1s"   v-if="!store.isApp">
                    <div class="title">联系我们</div>
                    <ul>
                        <li>
                            <img src="@/assets/iphone.png" alt="" srcset="">
                            <span>4006699955</span>
                        </li>
                        <li>
                            <img src="@/assets/qq.png" alt="" srcset="">
                            <span>25538</span>
                        </li>
                        <li>
                            <img src="@/assets/message.png" alt="" srcset="">
                            <span>25538@qq.com</span>
                        </li>
                        <!-- <li @click="clkonline" class="cursor-pointer">
                            <img src="@/assets/customer.png" alt="" srcset="">
                            <span>点击联系客服</span>
                        </li> -->
                    </ul>
                </div>
                <div class="right wow fadeInUp" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="lists d-flex align-items-center">
                        <div class="list">
                            <span class="title">Android扫码下载</span>
                            <img src="@/assets/code.png" alt="" srcset="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer d-flex justify-content-center align-items-center">
                江西喜神科技有限公司版权所有
                <span>
                    <a class="cursor-pointer" href="https://beian.miit.gov.cn/">赣ICP备2022007586号-4</a>&nbsp;&nbsp;
                </span>
                  增值电信业务经营许可证警<a href="https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E6%25B1%259F%25E8%25A5%25BF%25E5%2596%259C%25E7%25A5%259E%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke">赣B2-20220371</a>&nbsp;&nbsp;
                  在线数据处理与交易许可证<a href="https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E6%25B1%259F%25E8%25A5%25BF%25E5%2596%259C%25E7%25A5%259E%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke">赣B2-20220371</a>&nbsp;&nbsp;
            </div>
            <div class="footer footerSD d-flex justify-content-center align-items-center flex-column">
                <div class="d-flex align-items-center justify-content-center">
                    <img class="cursor-pointer" src="@/assets/A99A.png" alt="" srcset="">
                    <a class="cursor-pointer" href="https://beian.mps.gov.cn/#/query/webSearch?code=36110402000108">赣公网安备36110402000108 </a>
                </div>
            </div>
        </div>
        
        
    </div>
    <div @click="pageData.isKfShow = false" id="coverkefu" v-show="pageData.isKfShow">
        <img class="kefuimg" src="@/assets/kefu.png" alt="">
        <div class="kefutxt">{{store.isApp  ? "长按识别联系客服":"扫一扫联系客服"}}</div>
    </div>
    <customerHome
    ref="customerHomeRef"
    />
</template>

<script setup>
import customerHome from './customer.vue';
import { ref,reactive } from 'vue';
import { mainStore } from "@/sheep/store"

const pageData = reactive({
    isKfShow:false
})
const store = mainStore()
const customerHomeRef = ref(null);
function clkonline(){
    // customerHomeRef.value.show();
}
function go(){
    window.open('https://si.trustutn.org/info?sn=139230308040939782595&certType=1', '_blank');
}
</script>

<style lang="scss" scoped>
.footnoteHome{
    
    .content{
        padding: 5.2rem 0 3.3rem 0;
        .left{
            img{
                height: 3.6rem;
            }
        }
        .center{
            .title{
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom:2.6rem;
            }
            ul{
                li{
                    margin-bottom: 1.7rem;
                    display:flex;
                    align-items: center;
                    img{
                        width: 2.2rem;
                        margin-right: 1rem;
                    }
                    span{
                        font-size: 1.6rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                }
                li:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
        .right{
            .lists{
                .list{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 6.3rem;
                    span{
                       
                        font-size: 2rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom:1.5rem;
                    }
                    img{
                        width:13.5rem;
                    }
                }
            }
        }
    }
    .footer{
        font-size: 1.4rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(153,153,153,0.5);
        padding: 3.8rem;
        padding-bottom: 0;
        line-height: 2rem;
        a{
            color: rgba(153,153,153,0.5);
        }
    }
    .footerSD{
        padding-top: 0;
        text-align: center;
        padding-bottom: 3.8rem;
        width: 100%;
        .sdImg{
            width: 12rem;
        }
        div{
            margin-top: 1.5rem;
            img{
                margin-right: 0.5rem;
            }
        }
    }
}
#coverkefu{
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
}
.kefuimg{
    margin-top: 10%;
    width: 20%;
}
.kefutxt{
    margin-top: 30px;
    font-size: 25px;
    color: #fff;
}
@media screen and (max-width: 767px) {
    .footnoteHome {
        flex-direction: column;
        .foot-app{
            border-radius: $border-radius-con;
            box-sizing: border-box;
            .content{
                flex-wrap: wrap;
                padding: 13px 8px;
                background: white;
                margin: 6px 8px 0 8px;
                box-sizing: border-box;
                
                
            }
        }
        .foot-content{
            background: white;
            margin-top: 10px;
            .content{
                padding: 8px 20px;
                
            }
            .right{
                    .lists{
                        .list{
                            margin-right: 0;
                                flex-direction: column-reverse;
                            .title{
                                font-weight: 500;
                                font-size: 12px;
                                color: #333333;
                            }
                            img{
                                width: 70px;
                                margin-bottom: 5px;
                            }
                        }
                        .list:first-child{
                            margin-right: 24px;
                        }
                    }
                }
        }
        .footer{
            display: inline-block!important;
            text-align: center;
        }
    }
    
}

.right{
    .lists{
        .list{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 6.3rem;
            span{
            
                font-size: 2rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom:1.5rem;
            }
            img{
                width:13.5rem;
            }
        }
        .list:last-child{
            margin-right: 0;
        }
    }
}

</style>